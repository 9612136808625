@import url("https://fonts.googleapis.com/css?family=Slabo+27px");

.splash {
  position: relative;
  width: 83%;
  height: 175px;
  margin: 1% auto;
}

.animation-text {
  position: absolute;
  width: 100%;
  height: 50%;
  font-size: 45px;
  font-weight: bold;
  padding: 50px 10px;
  color: #edf9fe;
  font-family: "Agustina Regular";
}

.animation-text:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  line-height: 48px;
  left: auto;
  right: auto;
  /* top: 33%; */
  text-align: center;
  animation: abomination1 3.8s linear 1;
  animation-fill-mode: forwards;
}

@keyframes abomination1 {
  0% {
    content: "T";
  }

  8% {
    content: "To";
  }

  16% {
    content: "Tor";
  }

  24% {
    content: "Torc";
  }

  32% {
    content: "Torch";
  }

  40% {
    content: "Torchi";
  }

  48% {
    content: "Torchi R";
  }

  56% {
    content: "Torchi Ro";
  }

  64% {
    content: "Torchi Rok";
  }

  72% {
    content: "Torchi Roka";
  }

  80% {
    content: "Torchi Rokay";
  }

  88% {
    content: "Torchi Rokaya";
  }

  96% {
    content: "Torchi Rokaya.";
  }

  100% {
    content: "Torchi Rokaya...";
  }
}
